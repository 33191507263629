<template>
    <div class="content_area">
        <div class="content_wpr">
            <div class="section_content w-100" v-if="subscriptionLoader">
                <quote-loader />
            </div>
            <template v-else>
                <div class="section_content w-100" v-if="user.stripe_id">
                    <div class="section_header">
                        <h2>
                            Subscription Details Order<span>Simple billing options for your coaching business.</span>
                        </h2>
                    </div>
                    <div class="infocard_wpr">
                        <div class="info_card">
                            <div class="header">
                                Current Plan
                            </div>
                            <div class="info_body">
                                <div>
                                    <h3 class="uppercase">{{ subscription.plan_name }}</h3>
                                    <h6>Last updated on: {{ subscription.billing_start_date }}</h6>
                                </div>
                                <img src="@/assets/images/solo-plan.svg" alt="">
                            </div>
                        </div>
                        <div class="info_card">
                            <div class="header">
                                Next Billing Date
                            </div>
                            <div class="info_body">
                                <div>
                                    <div class="price"><span class="sup">$</span>{{ subscription.next_billing_amount }}</div>
                                    <h6>on {{ subscription.next_billing_date }}</h6>
                                </div>
                                <a class="discount">
                                    <label><span>Save</span> 16%<small>on</small></label>
                                    Annual Billing
                                    <span class="lh-7"><br/> Get 2-Months Free</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="plan_container">
                        <div class="subscription_wpr">
                            <div class="upgrade_area">
                                <div class="plan_type">
                                    Monthly
                                    <label for="plan" class="switch_option capsule_btn">
                                        <input type="checkbox" id="plan" true-value="year" false-value="month" v-model="planType" @change="calculateAddonsAmount()" hidden>
                                        <div><span></span></div>
                                    </label>
                                    Yearly
                                </div>
                                <button type="button" class="btn" @click="showAllPlans = false" v-if="showAllPlans">Back</button>
                                <button type="button" class="btn" @click="showAllPlans = true; scrollToPlan();" v-else>Change Plan</button>
                            </div>
                            <template v-for="(plan, p) of plans[planType]" :key="p">
                                <div class="subscription" :id="`plan-container-${p}`" v-if="showAllPlans || subscription.plan_name == plan.nickname">
                                    <div class="header">
                                        <h3><span class="uppercase">{{ plan.nickname }}</span> <span class="discount" v-if="plan.interval == 'year'">Save 16% (Get 2-Months Free)</span></h3>
                                        <div class="price"><span class="sup">$</span>{{ plan.amount }}<span class="sub">/{{ plan.interval }}ly</span></div>
                                    </div>
                                    <div class="features">
                                        <ul>
                                            <li v-for="(feature, f) of plan.features" :key="f"><i class="fas fa-check"></i>{{ feature }}</li>
                                        </ul>
                                        <div class="more_ft">
                                            <div>
                                                <h4 @click="toggleCoachingFeatures($event)">Coaching Feature Limits <i class="fas fa-chevron-down"></i></h4>
                                                <ul>
                                                    <li v-for="(feature, f) of plan.coaching_features" :key="f"><i class="fas fa-check"></i>{{ feature }}</li>
                                                </ul>
                                            </div>
                                            <a v-if="subscription.plan_name == plan.nickname && subscription.interval == plan.interval"><i class="fas fa-check"></i> Currently Subscribed</a>
                                            <button v-else type="button" class="btn" @click="upgradePlan(plan.nickname)">
                                                Choose plan
                                            </button>
                                        </div>
                                    </div>
                                    <div class="footer">
                                        <label for="add_plan" class="switch_option capsule_btn">
                                            <h5>Additional Options</h5>
                                            <input type="checkbox" id="add_plan" v-model="hasAddon" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </template>

                            <div class="setting_wpr">
                                <!-- <div class="billing_card">
                                    <div class="header">Coupon</div>
                                    <div class="card_body">
                                        <p class="mb-1">If you have any coupon, You can add here.</p>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <div class="field_wpr">
                                                    <input type="text" placeholder="Coupon code goes here..">
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button">Apply</button>
                                    </div>
                                </div> -->

                                <div class="billing_card">
                                    <div class="header">Cancel &amp; Delete</div>
                                    <div class="card_body">
                                        <p class="mb-1">If you want to delete your account permanently, please type DELETE Keyword in the box below.</p>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <div class="field_wpr">
                                                    <input type="text" placeholder="Type DELETE here.." v-model="deleteText">
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="delete_btn" @click="deleteText == 'DELETE' ? handleDeleteAccount() : ''" :class="deleteText == 'DELETE' ? 'active' : 'disable'">Cancel &amp; Delete</button>
                                    </div>
                                </div>
                                <div class="billing_card">
                                    <div class="header">Send Receipt to Email</div>
                                    <Form @submit="handleReceiptFormSubmit" v-slot="{ errors }" class="receipt-form" ref="receipt-form">
                                        <div class="card_body">
                                            <p>We will send a receipt downoad link to the email addresses that you specify below. You may seperate multiple email addresses using commas.</p>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Email Addressed</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.receipt_email }">
                                                        <Field autocomplete="off" name="receipt_email" v-model="receiptForm.receipt_email" :class="{ 'has-error': errors.receipt_email }" rules="required" label="receipt email">
                                                            <textarea name="" id="" cols="30" rows="10" v-model="receiptForm.receipt_email"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="receipt_email" class="text-danger" />
                                                </div>
                                            </div>
                                            <button><i class="fa fa-spin fa-spinner" v-if="recieptUpdateLoader"></i>&nbsp; {{ recieptUpdateLoader ? 'Saving' : 'Save' }}</button>
                                        </div>
                                    </Form>
                                </div>
                                <div class="billing_card">
                                    <div class="header">Additional Billing Info</div>
                                    <Form @submit="handleBillingInformationFormSubmit" v-slot="{ errors }" class="billing-nformation-form" ref="billing-nformation-form">
                                        <div class="card_body">
                                            <p>If you need to add specific contact or tax information to your receipts, like your full business name. VAT identification number, or address of record, you may add it here.</p>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Additional Billing Information</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.billing_information }">
                                                        <Field autocomplete="off" name="billing_information" v-model="billingInformationForm.billing_information" :class="{ 'has-error': errors.billing_information }" rules="required" label="billing information">
                                                            <textarea name="" id="" cols="30" rows="10" v-model="billingInformationForm.billing_information"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="billing_information" class="text-danger" />
                                                </div>
                                            </div>
                                            <button><i class="fa fa-spin fa-spinner" v-if="billingInformationUpdateLoader"></i>&nbsp; {{ billingInformationUpdateLoader ? 'Saving' : 'Save' }}</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div class="addOns_Wpr" v-if="hasAddon">
                            <!-- <div class="upgrade_area">
                                <h3>Total:</h3>
                                <div class="price">${{ addonsPricing.total }}</div>
                            </div> -->
                            <div class="addOn_card">
                                <div class="header">
                                    <h4>Current Subscription</h4>
                                </div>
                                <div class="addOn_info">
                                    <div class="invoice-info upgrade_area mb-1">
                                        <h5 class="small capitalize">{{ subscription.plan_name }} {{ subscription.interval }}ly Plan</h5>
                                        <div class="price mb-0">${{ addonsPricing.subscription_price }}/<span class="capitalize">{{ subscription.interval }}ly</span></div>
                                    </div>
                                    <div class="invoice-info upgrade_area">
                                        <h5 class="small">Additional Services</h5>
                                        <div class="price mb-0">${{ currentSubscriptionAddonsPricing.addons_total }}/Monthly</div>
                                    </div>
                                    <div class="invoice-info upgrade_area seperater mt-2">
                                        <h5 class="small">Total:</h5>
                                        <div class="price mb-0">${{ currentSubscriptionAddonsPricing.total }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="addOn_card">
                                <div class="header">
                                    <h4>Unlimited Thrive Funnels </h4>
                                </div>
                                <div class="addOn_info">
                                    <div class="price ml-auto"><span class="sup">$</span>{{ addonsPricing.fit_funnels_price }}<span class="sub">/monthly</span></div>
                                    <div class="extra_wpr">
                                        <label for="add_funnels" class="switch_option capsule_btn">
                                            <h5 class="small">Thrive Funnels Unlimited</h5>
                                            <input type="checkbox" id="add_funnels" :disabled="user.hibernated" v-if="subscription.plan_name == 'pro'" v-model="hasFunnels" :true-value="1" :false-value="0" hidden @change="calculateAddonsAmount(); subscriptionUpdateWithAddons('funnel', hasFunnels);">
                                            <input type="checkbox" id="add_funnels" :disabled="user.hibernated" v-else v-model="hasFunnels" :true-value="1" :false-value="0" hidden @change="upgradeAlert('funnel', 'pro'); hasFunnels = 0;">
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="addOn_card">
                                <div class="header">
                                    <h4>Mindbody Sync </h4>
                                </div>
                                <div class="addOn_info">
                                    <div class="price ml-auto"><span class="sup">$</span>{{ addonsPricing.mindbody_price }}<span class="sub">/monthly</span></div>
                                    <div class="extra_wpr">
                                        <label for="add_mindbody" class="switch_option capsule_btn">
                                            <h5 class="small">Mindbody Sync</h5>
                                            <input type="checkbox" id="add_mindbody" v-model="hasMindbody" :true-value="1" :false-value="0" :disabled="user.hibernated" hidden @change="calculateAddonsAmount(); subscriptionUpdateWithAddons('mindbody', hasMindbody);">
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="addOn_card">
                                <div class="header">
                                    <h4>Additional Contacts</h4>
                                    <button type="button" :disabled="user.hibernated" @click="subscriptionUpdateWithAddons('contact')" v-if="contactSlider.move">Confirm</button>
                                    <button type="button" :disabled="user.hibernated" :class="{ disabled: subscription.plan_name != 'pro' }" @click="subscription.plan_name == 'pro' ? contactSlider.move = true : upgradeAlert('contact')" v-else>{{ subscription.plan_name != 'pro' ? 'Upgrade' : 'Update' }}</button>
                                </div>
                                <div class="addOn_info">
                                    <div class="price ml-auto"><span class="sup">$</span>{{ addonsPricing.contact_price }}<span class="sub">/monthly</span></div>
                                    <div class="range_slider" :class="{'no-move' : !contactSlider.move}">
                                        <span :style="`width: ${(100 / contactSlider.max) * contactSlider.contacts }%`">
                                        <label>{{contactSlider.contacts}}</label>
                                        </span>
                                        <input ref="input" class="slider" v-model="contactSlider.contacts" type="range" :min="contactSlider.min" :max="contactSlider.max" step="1000" @input="calculateAddonsAmount()">
                                        <ul class="range_label">
                                            <li>0</li>
                                            <li>{{contactSlider.max}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="addOn_card">
                                <div class="header">
                                    <h4>Additional Coaches</h4>
                                    <button type="button" :disabled="user.hibernated" @click="subscriptionUpdateWithAddons('user')" v-if="userSlider.move">Confirm</button>
                                    <button type="button" :disabled="user.hibernated" :class="{ disabled: subscription.plan_name != 'pro' }" @click="subscription.plan_name == 'pro' ? userSlider.move = true : upgradeAlert('coach')" v-else>{{ subscription.plan_name != 'pro' ? 'Upgrade' : 'Update' }}</button>
                                </div>
                                <div class="addOn_info">
                                    <div class="price ml-auto"><span class="sup">$</span>{{ addonsPricing.user_price }}<span class="sub">/monthly</span></div>
                                    <div class="range_slider" :class="{'no-move' : !userSlider.move}">
                                        <span :style="`width: ${(100 / userSlider.max) * userSlider.users }%`">
                                        <label>{{userSlider.users}}</label>
                                        </span>
                                        <input ref="input" class="slider" v-model="userSlider.users" type="range" :min="userSlider.min" :max="userSlider.max" step="10" @input="calculateAddonsAmount()">
                                        <ul class="range_label">
                                            <li>0</li>
                                            <li>{{userSlider.max}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="addOn_card">
                                <div class="header">
                                    <h4>Additional Sub-account</h4>
                                    <button type="button" :disabled="user.hibernated" @click="subscriptionUpdateWithAddons('location')" v-if="locationSlider.move">Confirm</button>
                                    <button type="button" :disabled="user.hibernated" :class="{ disabled: subscription.plan_name != 'pro' }" @click="subscription.plan_name == 'pro' ? locationSlider.move = true : upgradeAlert('location')" v-else>{{ subscription.plan_name != 'pro' ? 'Upgrade' : 'Update' }}</button>
                                </div>
                                <div class="addOn_info">
                                    <div class="price ml-auto"><span class="sup">$</span>{{ addonsPricing.location_price }}<span class="sub">/monthly</span></div>
                                    <div class="range_slider" :class="{'no-move' : !locationSlider.move}">
                                        <span :style="`width: ${(100 / locationSlider.max) * locationSlider.locations }%`">
                                        <label>{{locationSlider.locations}}</label>
                                        </span>
                                        <input ref="input" class="slider" v-model="locationSlider.locations" type="range" :min="locationSlider.min" :max="locationSlider.max" step="1" @input="calculateAddonsAmount()">
                                        <ul class="range_label">
                                            <li>0</li>
                                            <li>{{locationSlider.max}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="addOn_card">
                                <div class="header">
                                    <h4>Additional Storage</h4>
                                    <button type="button" :disabled="user.hibernated" :class="{ disabled: user.hibernated }" @click="subscriptionUpdateWithAddons('storage')" v-if="storageSlider.move">Confirm</button>
                                    <button type="button" :disabled="user.hibernated" :class="{ disabled: user.hibernated }" @click="storageSlider.move = true" v-else>Update</button>
                                </div>
                                <div class="addOn_info">
                                    <div class="price ml-auto"><span class="sup">$</span>{{ addonsPricing.storage_price }}<span class="sub">/monthly</span></div>
                                    <div class="range_slider" :class="{'no-move' : !storageSlider.move}">
                                        <span :style="`width: ${storageSlider.storage}%`">
                                        <label>{{storageSlider.storage}}</label>
                                        </span>
                                        <input ref="input" v-model="storageSlider.storage" type="range" :min="storageSlider.min" :max="storageSlider.max" step="1" class="slider" @input="calculateAddonsAmount()">
                                        <ul class="range_label">
                                            <li>0 GB</li>
                                            <li>{{storageSlider.max}} GB</li>
                                        </ul>
                                    </div>
                                    <div class="storage">
                                        <span :style="`width: ${ fileStorage.used_percentage }%;`"></span>
                                        <h4>Storage: {{ fileStorage.available }} {{ fileStorage.unit }} / {{ fileStorage.total }} {{ fileStorage.unit }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_content w-100" v-else>
                    <div class="migrate-to-thrive">
                        <h3 class="mb-3">Your account is a legacy account and your billing is the lowest rate we offer. If you need to update your billing please email <a href="mailto:support@thrivecoach.io">support@thrivecoach.io</a></h3>
                        <p class="mb-5">If you wish to migrate to Thrive Coach billing you can click the Migrate button. WARNING: Doing so will disable your Onboard Me account permanently.</p>
                        <a class="btn pull-right" href="mailto:support@thrivecoach.io">Migrate to Thrive Coach</a>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <confirm-payment v-model="showConfirmPayment" :payment="payment" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ConfirmPayment = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/ConfirmPayment'))

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    const stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {});

    export default {
        data () {
            return {
                planType: 'month',
                showAllPlans: false,
                hasFunnels: 0,
                hasMindbody: 0,
                hasAddon: true,
                deleteText: '',
                contactSlider:{
                    max: 100000,
                    min: 0,
                    contacts: 0,
                    move: false,
                },
                userSlider:{
                    max: 500,
                    min: 0,
                    users: 0,
                    move: false,
                },
                locationSlider:{
                    max: 10,
                    min: 0,
                    locations: 0,
                    move: false,
                },
                storageSlider:{
                    max: 100,
                    min: 0,
                    storage: 0,
                    move: false,
                },
                stripeToken: null,
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                showConfirmPayment: false,
                payment: {},
                addonsPricing: {
                    total: 0,
                    contact_price: 0,
                    contact_unit: 0,
                    location_price: 0,
                    location_unit: 0,
                    user_price: 0,
                    user_unit: 0,
                    storage_price: 0,
                    storage_unit: 0,
                    fit_funnels_price: 0,
                    mindbody_price: 0,
                },
                currentSubscriptionAddonsPricing: {
                    total: 0,
                    contact_price: 0,
                    contact_unit: 0,
                    location_price: 0,
                    location_unit: 0,
                    user_price: 0,
                    user_unit: 0,
                    storage_price: 0,
                    storage_unit: 0,
                    fit_funnels_price: 0,
                    mindbody_price: 0,
                },
                plans: [],
                addons: [],
                receiptForm: {
                    receipt_email: '',
                },
                billingInformationForm: {
                    billing_information: '',
                },
                recieptUpdateLoader: false,
                billingInformationUpdateLoader: false,
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
            ConfirmPayment,
        },

        watch: {
            subscription (subscription) {
                const vm = this;

                vm.planType = subscription.interval;
                vm.hasFunnels = subscription.has_funnels;
                vm.hasMindbody = subscription.has_mindbody;
                vm.addons = vm.plansAndAddons.addons;

                vm.plans.month.sort((x, y) => x.nickname == subscription.plan_name ? -1 : y.nickname == subscription.plan_name ? 1 : 0);
                vm.plans.year.sort((x, y) => x.nickname == subscription.plan_name ? -1 : y.nickname == subscription.plan_name ? 1 : 0);

                vm.resetAddonsQuantity();
                vm.calculateAddonsAmount();
                vm.calculateCurrentSubscriptionAddonsAmount();
            },

            planType (type) {
                const vm = this;

                vm.addons = vm.plansAndAddons.addons;
                vm.calculateAddonsAmount();
            },

            plansAndAddons (plansAndAddons) {
                const vm = this;

                vm.plans = JSON.parse(JSON.stringify(plansAndAddons.plans));
                vm.addons = plansAndAddons.addons;
                vm.calculateAddonsAmount();
            },

            billingProfile (billingProfile) {
                const vm = this;

                vm.receiptForm.id = billingProfile.id;
                vm.receiptForm.receipt_email = billingProfile.receipt_email;
                vm.billingInformationForm.id = billingProfile.id;
                vm.billingInformationForm.billing_information = billingProfile.billing_information;
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                plansAndAddons: state => state.billingModule.plans,
                subscription: state => state.billingModule.subscription,
                subscriptionLoader: state => state.billingModule.subscriptionLoader,
                fileStorage: state => state.authModule.fileStorage,
                billingProfile: state => state.billingModule.billingProfile,
            }),

            stripeElements () {
                return stripeInstance.elements();
            },
        },

        mounted () {
            const vm = this;

            // if (!vm.subscription.id) {
                vm.getSubscription();
            // }

            setTimeout(function () {
                vm.plans  = vm.plansAndAddons ? JSON.parse(JSON.stringify(vm.plansAndAddons.plans)) : [];
                vm.addons = vm.plansAndAddons ? vm.plansAndAddons.addons : [];
            }, 10);

            vm.calculateAddonsAmount();

            if (!vm.billingProfile.id) {
                vm.getBillingProfile();
            } else {
                vm.receiptForm.id = vm.billingProfile.id;
                vm.receiptForm.receipt_email = vm.billingProfile.receipt_email;
                vm.billingInformationForm.id = vm.billingProfile.id;
                vm.billingInformationForm.billing_information = vm.billingProfile.billing_information;
            }
        },

        beforeUnmount () {
            const vm = this;

            if (vm.cardNumber) {
                vm.cardNumber.destroy();
                vm.cardExpiry.destroy();
                vm.cardCvc.destroy();
            }
        },

        methods: {
            ...mapActions({
                getSubscription: 'billingModule/getSubscription',
                getBillingProfile: 'billingModule/getBillingProfile',
                updateSubscriptionInfo: 'billingModule/updateSubscriptionInfo',
                refreshAuth: 'authModule/refreshAuth',
                logoutUser: 'authModule/logout',
            }),

            scrollToPlan () {
                setTimeout(function () {
                    const el = document.getElementById('plan-container-1');

                    if (el) {
                        el.scrollIntoView();
                    }
                }, 10);
            },

            upgradePlan (plan) {
                const vm = this;
                const interval = vm.planType;
                let status = 'upgrade';

                if ((vm.subscription.plan_name == 'solo' && (plan == 'growth' || plan == 'pro')) || (vm.subscription.plan_name == 'growth' && plan == 'pro')) {
                    status = 'upgrade'
                } else if ((vm.subscription.plan_name == 'growth' && plan == 'solo') || vm.subscription.plan_name == 'pro' && (plan == 'solo' || plan == 'growth')) {
                    status = 'downgrade'
                }

                if (status == 'downgrade' && ((plan == 'solo' && vm.subscription.addons_solo.length) || (plan == 'growth' && vm.subscription.addons_growth.length))) {
                    const addonNames = plan == 'solo' ? vm.subscription.addons_solo.join(', ') : vm.subscription.addons_growth.join(', ');
                    const options = Helper.swalWarningOptions('', `Please deactivate the ${addonNames} addons to downgrade to ${plan}.`);

                    Swal.fire(options);
                } else if (status == 'downgrade' && vm.subscription.extra_consumption[plan] && Object.values(vm.subscription.extra_consumption[plan]).length) {
                    if (vm.subscription.downgrade_count >= 2) {
                        const options = Helper.swalWarningOptions('', 'Your maximum downgrade allowance has been reached. Please contact support. <br><a href="mailto:support@thrivecoach.io">support@thrivecoach.io</a>');

                        Swal.fire(options);
                    } else {
                        let subscription = { np: plan, op: vm.subscription.plan_name, ni: vm.planType, oi: vm.subscription.interval };
                            subscription = (btoa(JSON.stringify(subscription))).replaceAll('==', '');

                        vm.$router.push({ name: 'AccountDowngrade', params: { subscription } });
                    }
                } else if (status == 'downgrade' && vm.subscription.downgrade_count >= 2) {
                    const options = Helper.swalWarningOptions('', 'Your maximum downgrade allowance has been reached. Please contact support. <br><a href="mailto:support@thrivecoach.io">support@thrivecoach.io</a>');

                    Swal.fire(options);
                } else {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${status} your subscription to ${plan}`, 'Yes');

                    options.preConfirm = () => {
                                              return new Promise(function(resolve, reject) {
                                                  axios.post(`${vm.billingApi}/stripe/subscription/${plan}/swap`, { interval }, {
                                                      headers: {
                                                          Accept: 'application/json',
                                                          Authorization: `Bearer ${vm.user.access_token}`,
                                                      },
                                                  }).then((resp) => {
                                                      vm.getSubscription();

                                                      Toastr.success(`Subscription has been ${status == 'upgrade' ? 'upgraded' : 'downgraded'} successfully!`);
                                                      resolve(true);
                                                  }).catch((err) => {
                                                      Toastr.error(err.response.data.error);
                                                      resolve(true);
                                                  });
                                              });
                                          };

                    Swal.fire(options);
                }
            },

            toggleCoachingFeatures (e) {
                let el = e.currentTarget.nextSibling;

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }
            },

            calculateAddonsAmount () {
                const vm                = this;
                let total               = 0;
                let contact_price       = 0;
                let user_price          = 0;
                let location_price      = 0;
                let storage_price       = 0;
                let fit_funnels_price   = 0;
                let mindbody_price      = 0;
                let subscription_price  = 0;
                let addons_total        = 0;
                const addonsPrice       = {};

                if (vm.addons && vm.addons.month && vm.addons.month.length) {
                    vm.addons.month.forEach((plan) => {
                        if (plan.nickname == 'contact') {
                            addonsPrice[plan.nickname] = plan.amount / 100;
                        } else {
                            addonsPrice[plan.nickname] = plan.amount;
                        }
                    });
                }

                const contact_unit      = vm.contactSlider.contacts;
                const user_unit         = vm.userSlider.users;
                const location_unit     = vm.locationSlider.locations;
                const storage_unit      = vm.storageSlider.storage;
                const contactUnitPrice  = addonsPrice.contact; //Contact unit price
                const userUnitPrice     = addonsPrice.user; //User unit price
                const locationUnitPrice = addonsPrice.location; //Location unit price
                const storageUnitPrice  = addonsPrice.storage; //Storage unit price
                const fitFunnelPrice    = addonsPrice.funnel; //Fit funnels unit price
                const mindbodyPrice     = addonsPrice.mindbody; //MindBody sunc unit price

                contact_price       = parseFloat(Math.round((contact_unit * contactUnitPrice)* 100) / 100);
                user_price          = parseFloat(Math.round((user_unit * userUnitPrice)* 100) / 100);
                location_price      = parseFloat(Math.round((location_unit * locationUnitPrice)* 100) / 100);
                storage_price       = parseFloat(Math.round((storage_unit * storageUnitPrice)* 100) / 100);
                fit_funnels_price   = vm.hasFunnels ? parseFloat(Math.round(fitFunnelPrice * 100) / 100) : 0;
                mindbody_price      = vm.hasMindbody ? parseFloat(Math.round(mindbodyPrice * 100) / 100) : 0;
                subscription_price  = Math.round(vm.subscription.amount * 100) / 100;
                addons_total        = Math.round((contact_price + user_price + location_price + storage_price + fit_funnels_price) * 100) / 100;
                total               = Math.round((parseFloat(vm.subscription.amount) + contact_price + user_price + location_price + storage_price + fit_funnels_price + mindbody_price) * 100) / 100;

                vm.addonsPricing = {
                    total,
                    subscription_price,
                    addons_total,
                    contact_price,
                    contact_unit,
                    location_price,
                    location_unit,
                    user_price,
                    user_unit,
                    storage_price,
                    storage_unit,
                    fit_funnels_price,
                    mindbody_price,
                };
            },

            calculateCurrentSubscriptionAddonsAmount () {
                const vm                = this;
                let total               = 0;
                let contact_price       = 0;
                let user_price          = 0;
                let location_price      = 0;
                let storage_price       = 0;
                let fit_funnels_price   = 0;
                let mindbody_price      = 0;
                let subscription_price  = 0;
                let addons_total        = 0;
                const addonsPrice       = {};

                if (vm.addons && vm.addons.month && vm.addons.month.length) {
                    vm.addons.month.forEach((plan) => {
                        if (plan.nickname == 'contact') {
                            addonsPrice[plan.nickname] = plan.amount / 100;
                        } else {
                            addonsPrice[plan.nickname] = plan.amount;
                        }
                    });
                }

                const contact_unit      = vm.contactSlider.contacts;
                const user_unit         = vm.userSlider.users;
                const location_unit     = vm.locationSlider.locations;
                const storage_unit      = vm.storageSlider.storage;
                const contactUnitPrice  = addonsPrice.contact; //Contact unit price
                const userUnitPrice     = addonsPrice.user; //User unit price
                const locationUnitPrice = addonsPrice.location; //Location unit price
                const storageUnitPrice  = addonsPrice.storage; //Storage unit price
                const fitFunnelPrice    = addonsPrice.funnel; //Fit funnels unit price
                const mindbodyPrice     = addonsPrice.mindbody; //Mindbody sync unit price

                contact_price       = parseFloat(Math.round((contact_unit * contactUnitPrice)* 100) / 100);
                user_price          = parseFloat(Math.round((user_unit * userUnitPrice)* 100) / 100);
                location_price      = parseFloat(Math.round((location_unit * locationUnitPrice)* 100) / 100);
                storage_price       = parseFloat(Math.round((storage_unit * storageUnitPrice)* 100) / 100);
                fit_funnels_price   = vm.hasFunnels ? parseFloat(Math.round(fitFunnelPrice * 100) / 100) : 0;
                mindbody_price      = vm.hasMindbody ? parseFloat(Math.round(mindbodyPrice * 100) / 100) : 0;
                subscription_price  = Math.round(vm.subscription.amount * 100) / 100;
                addons_total        = Math.round((contact_price + user_price + location_price + storage_price + fit_funnels_price) * 100) / 100;
                total               = Math.round((parseFloat(vm.subscription.amount) + contact_price + user_price + location_price + storage_price + fit_funnels_price + mindbody_price) * 100) / 100;

                vm.currentSubscriptionAddonsPricing = {
                    total,
                    subscription_price,
                    addons_total,
                    contact_price,
                    contact_unit,
                    location_price,
                    location_unit,
                    user_price,
                    user_unit,
                    storage_price,
                    storage_unit,
                    fit_funnels_price,
                    mindbody_price,
                };
            },

            subscriptionUpdateWithAddons (addon, funnel = 0) {
                const vm      = this;
                const plan    = vm.subscription.plan_name;
                let quantity  = 0;
                let title     = '';

                if (vm.subscription.payment_method === 0) {
                    let addonTitle = '';

                    if (addon == 'contact') {
                        addonTitle = 'Additional Contacts';
                    } else if (addon == 'user') {
                        addonTitle = 'Additional Coaches';
                    } else if (addon == 'location') {
                        quantity    = vm.locationSlider.locations;
                        addonTitle  = `Additional ${ quantity > 1 ? 'Locations' : 'Location' }`;
                    } else if (addon == 'storage') {
                        addonTitle = 'Additional Storage';
                    } else if (addon == 'funnel') {
                        addonTitle = 'Thrive Funnels';
                    } else if (addon == 'mindbody') {
                        addonTitle = 'Unlimited Mindbody';
                    }

                    const warning = Helper.swalWarningOptions('Oops!', `Looks like you used Stripe Link as your trial method. To activate ${addonTitle} you will need to add a card on file. Please go to payment methods and add a card before proceeding.`);

                    Swal.fire(warning);

                    vm.hasFunnels           = 0;
                    vm.hasMindbody          = 0;
                    vm.contactSlider.move   = false;
                    vm.userSlider.move      = false;
                    vm.locationSlider.move  = false;
                    vm.storageSlider.move   = false;

                    return false;
                }

                if (addon == 'contact') {
                    quantity  = vm.contactSlider.contacts / 100;
                    title     = quantity ? `Do you want to update your subscription with ${quantity} additional contacts for and additional $${vm.addonsPricing.contact_price} monthly?` : 'Do you want to update your subscription with 0 additional contacts?';
                } else if (addon == 'user') {
                    quantity  = vm.userSlider.users;
                    title     = quantity ? `Do you want to update your subscription with ${quantity} additional coaches for and additional $${vm.addonsPricing.user_price} monthly?` : 'Do you want to update your subscription with 0 additional coaches?';
                } else if (addon == 'location') {
                    quantity  = vm.locationSlider.locations;
                    title     = quantity ? `Do you want to update your subscription with ${quantity} additional ${ quantity > 1 ? 'locations' : 'location' } for and additional $${vm.addonsPricing.location_price} monthly?` : 'Do you want to update your subscription with 0 additional location?';
                } else if (addon == 'storage') {
                    quantity  = vm.storageSlider.storage;
                    title     = quantity ? `Do you want to upgrade your subscription with ${quantity} GB of storage for an additional $${vm.addonsPricing.storage_price} monthly?` : 'Do you want to update your subscription with 0 GB of storage?';
                } else if (addon == 'funnel') {
                    const funnelPrice = vm.addons.month.filter((price) => price.nickname == 'funnel')[0];
                    quantity  = funnel;
                    title     = funnel ? `Do you want to upgrade your plan to include unlimited Thrive funnels for $${Math.round(funnelPrice.amount * 100) / 100} per month?` : `Do you want to downgrade your plan to exclude unlimited Thrive funnels?`;
                } else if (addon == 'mindbody') {
                    const mindbodyPrice = vm.addons.month.filter((price) => price.nickname == 'mindbody')[0];
                    quantity  = funnel;
                    title     = funnel ? `Do you want to upgrade your plan to include unlimited mindbody sync for $${Math.round(mindbodyPrice.amount * 100) / 100} per month?` : `Do you want to downgrade your plan to exclude unlimited mindbody sync?`;
                }

                if (
                    (addon == 'contact' && parseInt(vm.subscription.extra_consumption.current_consumption.contacts) > (vm.subscription.extra_consumption[`${plan}_limit`].contacts + parseInt(quantity))) ||
                    (addon == 'user' && parseInt(vm.subscription.extra_consumption.current_consumption.coaches) > (vm.subscription.extra_consumption[`${plan}_limit`].coaches + parseInt(quantity))) ||
                    (addon == 'location' && parseInt(vm.subscription.extra_consumption.current_consumption.locations) > (vm.subscription.extra_consumption[`${plan}_limit`].locations + parseInt(quantity)))
                ) {
                    let extra = 0;

                    if (addon == 'contact') {
                        extra = parseInt(vm.subscription.extra_consumption.current_consumption.contacts) - (vm.subscription.extra_consumption[`${plan}_limit`].contacts + parseInt(quantity));
                    } else if (addon == 'user') {
                        extra = parseInt(vm.subscription.extra_consumption.current_consumption.coaches) - (vm.subscription.extra_consumption[`${plan}_limit`].coaches + parseInt(quantity));
                    } else if (addon == 'location') {
                        extra = parseInt(vm.subscription.extra_consumption.current_consumption.locations) - (vm.subscription.extra_consumption[`${plan}_limit`].locations + parseInt(quantity));
                    }

                    const options = Helper.swalWarningOptions('', `You cannot update your subscription until you delete ${extra} extra ${addon == 'user' ? 'coach' : addon }s.`);

                    Swal.fire(options);
                } else {
                    const params  = { addon, quantity, interval: vm.planType };
                    const options = Helper.swalConfirmOptions('Are you sure?', title, 'Yes');

                    options.preConfirm = () => {
                                              return new Promise(function(resolve, reject) {
                                                  axios.post(`${vm.billingApi}/stripe/subscription/${plan}/upgrade`, params, {
                                                      headers: {
                                                          Accept: 'application/json',
                                                          Authorization: `Bearer ${vm.user.access_token}`,
                                                      },
                                                  }).then((resp) => {
                                                      vm.getSubscription();
                                                      vm.refreshAuth();

                                                      Toastr.success(`Subscription has been updated successfully!`);
                                                      resolve(true);
                                                  }).catch((err) => {
                                                      Toastr.error(err.response.data.error);
                                                      resolve(true);
                                                  });
                                              });
                                          };

                    Swal.fire(options).then((confirm) => {
                        if (confirm.isDismissed) {
                            if (addon == 'funnel') {
                                vm.hasFunnels = funnel ? 0 : 1;
                            }

                            if (addon == 'mindbody') {
                                vm.hasMindbody = funnel ? 0 : 1;
                            }

                            vm.resetAddonsQuantity();
                            vm.calculateAddonsAmount();
                        }

                        if (addon == 'contact') {
                            vm.contactSlider.move = false;
                        } else if (addon == 'user') {
                            vm.userSlider.move = false;
                        } else if (addon == 'location') {
                            vm.locationSlider.move = false;
                        } else if (addon == 'storage') {
                            vm.storageSlider.move = false;
                        }
                    });
                }
            },

            resetAddonsQuantity () {
                const vm = this;

                vm.contactSlider.contacts   = vm.subscription.additional_contacts;
                vm.userSlider.users         = vm.subscription.additional_users;
                vm.locationSlider.locations = vm.subscription.additional_locations;
                vm.storageSlider.storage    = vm.subscription.additional_storage;
                vm.hasFunnels               = vm.subscription.has_funnels;
            },

            handleReceiptFormSubmit (form, { setFieldError }) {
                const vm = this;

                const params = vm.receiptForm;
                params.setFieldError = setFieldError;
                vm.recieptUpdateLoader = true;

                vm.updateSubscriptionInfo(params).then((result) => {
                    if (result) {
                        Toastr.success('Email recipients has been updated successfully!');
                    }

                    vm.recieptUpdateLoader = false;
                });
            },

            handleBillingInformationFormSubmit (form, { setFieldError }) {
                const vm = this;

                const params = vm.billingInformationForm;
                params.setFieldError = setFieldError;
                vm.billingInformationUpdateLoader = true;

                vm.updateSubscriptionInfo(params).then((result) => {
                    if (result) {
                        Toastr.success('Billing information has been updated successfully!');
                    }

                    vm.billingInformationUpdateLoader = false;
                });
            },

            handleDeleteAccount () {
                const vm = this;
                vm.$router.push({name: 'CancellationIndex'});
            },


            upgradeAlert (feature, plan = 'pro') {
                const options = Helper.swalWarningOptions('', `Please upgrade your subscription to ${plan} to access this feature`);

                Swal.fire(options);
            },
        },
    }
</script>

<style scoped>
    .section_header,
    .section_header h2 {
        width: 100%;
    }

    .profile-form .action_wpr {
        border-top: 0px solid #eaeaea !important;
        margin: 20px 0 0 !important;
        padding: 0px !important;
    }

    .subscription-model .paymentcard_wpr {
        display: block;
        margin: 0;
    }

    .subscription-model .paymentcard_wpr .payment_card {
        padding: 10px;
        margin: 5px 0px;
        background: transparent;
        box-shadow: none;
        border: 1px solid #dfdfdf;
    }

    .subscription-model .paymentcard_wpr .payment_card.has-error {
        border-color: #eb1414 !important;
    }

    .subscription-model .paymentcard_wpr .payment_card .price-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #card-error {
        color: #eb1414;
    }

    .StripeElement {
        border: 0;
        width: 100%;
        height: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 15px;
        box-sizing: border-box;
        background: transparent;
    }

    :deep(.__PrivateStripeElement),
    :deep(.__PrivateStripeElement iframe) {
        height: 50px !important;
    }
    :deep(.__PrivateStripeElement iframe .InputElement) {
        height: 50px !important;
    }

    .d-none {
        display: none;
    }

    .migrate-to-thrive {
        display: flex;
        height: 90vh;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0px 50px;
        text-align: center;
    }

    .section_content .quote_wpr {
        min-height: 90vh;
        justify-content: center;
    }

    .addOn_card .header button.disabled {
        background: #757575;
    }

    .addOns_Wpr .invoice-info.upgrade_area {
        background: #FFF;
        padding: 0px;
        margin: 0;
    }

    .addOns_Wpr .invoice-info.upgrade_area.seperater {
        border-top: 1px solid #757575;
    }

    .addOns_Wpr .invoice-info.upgrade_area h5,
    .addOns_Wpr .invoice-info.upgrade_area .price {
        line-height: 20px;
        font-weight: 500;
        font-size: 13px;
    }

    .migrate-to-thrive a.btn {
        font-size: 12px;
        line-height: 15px;
        background: #2f7eed;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 20px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        text-decoration: none;
    }

    .migrate-to-thrive a {
        text-decoration: none;
        color: #2f7eed;
    }
</style>
